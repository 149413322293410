.fileInfo-Thumb {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border: 1px solid #808080; /* 회색 테두리 */
    border-radius: 10px; /* 둥글게 처리 */
    background-color:lightgray;
}

.fileInfo-Thumb img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}