@mixin filledStyle() {
  background-color: #ecf1ff;
  color: white;
}

@mixin alignCenter() {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -ms-flex-direction: column;
}

.DragDrop {
  width: 100%;
  height: 100vh;

  @include alignCenter();

  &-File {
    borderRadius: 8px;
    margin:0;
    padding:0;
    border: 1px solid #dadada;
    width :100%;
    height: calc(100% - 54px);
    display:flex;

    &-Dragging {
      border: 5px solid #155cff;
      margin:0;
      padding:0;
      width :100%;
      height: calc(100% - 54px);
      display:flex;
      @include filledStyle();
    }
  }

  &-Files {
    margin-top: 1rem;

    & > div {
      width: 300px;
      padding: 8px;
      border: 1px solid black;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }

    &-Filter {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}