
.ant-card .ant-card-head {
    padding: 2px 4px;
    display: flex; /* 컨테이너를 flexbox로 설정합니다. */
    justify-content: space-between; /* 좌우로 분리된 두 개의 요소를 배치합니다. */
    min-height: 40px;
    background-color:#E0F1FF;
}

.ant-card .ant-card-head-wrapper{
    display: block;
}

.ant-card .ant-card-body
{
    padding: 5px;
    display: flex;
}
.ant-card .ant-card-actions
{
    /*background-color:#E0F1FF;*/
}
.ant-card .ant-card-actions>li
{
    margin: 0;
}

.ant-card .ant-card-actions>li >span >.anticon
{
    line-height: 40px;
}

.thumb_filename {
    display: flex; /* 컨테이너를 flexbox로 설정합니다. */
    justify-content: space-between; /* 좌우로 분리된 두 개의 요소를 배치합니다. */
    color: #4b4b4b;
    font-weight:bold;
    position: relative;
}

.thumb_filename .left {

    text-align: left;
    width: 18px;
    margin-right: 2px;

}

.thumb_filename .right {
    width: 100%; /* 너비를 50%로 설정합니다. */
    max-width: 100%; /* 컨테이너의 최대 너비를 100%로 설정합니다. */
    display: -webkit-box; /* 레이아웃에 WebKit의 flexbox를 사용합니다. */
    -webkit-box-orient: vertical; /* 텍스트를 수직으로 정렬합니다. */
    -webkit-line-clamp: 1; /* 최대 2줄까지 허용하고 3줄이 넘어가면 점 세 개로 표시합니다. */
    overflow: hidden; /* 텍스트가 컨테이너를 넘어가는 부분을 숨깁니다. */
    text-align: left;
    align-items: flex-end;
}


.thumbContainer {
    width: 100%;
    min-width: 100px;
    height: 150px;
    overflow: hidden;
    position: relative;
    padding: 5px 0;
}

.thumbContainer-area {
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
}

.thumbContainer-img:hover{
    transform: scale(1.2);
}

.thumbContainer-img {
    object-fit: cover;
    transition: transform 0.3s ease;
}

.thumbContainer-button {
    position: absolute;
    right: 7px;
}

.thumbContainer-button2 {
    position: absolute;
    right: 7px;
    top: 40px;
}

.thumbContainer-icon {
    position: absolute;
    left: 0;
    top: calc(100% - 42px);
    margin: 2px;
    z-index: 1;
}


