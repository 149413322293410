/* CSS 파일 */
.ant-table-cell {
    padding: 0;
    margin: 0;
}

/* CSS 파일 */
.ant-table-body {
    font-size: 13px;
}

.ant-table-thead {
    font-size: 13px;
}

.fileListRightBtn {
    margin-left: 5px;
}


.col_filename {
    width: 100%; /* 너비를 50%로 설정합니다. */
    max-width: 100%; /* 컨테이너의 최대 너비를 100%로 설정합니다. */
    display: -webkit-box; /* 레이아웃에 WebKit의 flexbox를 사용합니다. */
    -webkit-box-orient: vertical; /* 텍스트를 수직으로 정렬합니다. */
    -webkit-line-clamp: 1; /* 최대 2줄까지 허용하고 3줄이 넘어가면 점 세 개로 표시합니다. */
    overflow: hidden; /* 텍스트가 컨테이너를 넘어가는 부분을 숨깁니다. */
}

.col_filename2 {
    display: flex; /* 컨테이너를 flexbox로 설정합니다. */
    justify-content: space-between; /* 좌우로 분리된 두 개의 요소를 배치합니다. */
    color: #8c8c8c;
    font-weight:500;
}

.col_filename2 .left {
    width: 30%; /* 너비를 50%로 설정합니다. */
    max-width: 30%; /* 컨테이너의 최대 너비를 100%로 설정합니다. */
    display: -webkit-box; /* 레이아웃에 WebKit의 flexbox를 사용합니다. */
    -webkit-box-orient: vertical; /* 텍스트를 수직으로 정렬합니다. */
    -webkit-line-clamp: 1; /* 최대 2줄까지 허용하고 3줄이 넘어가면 점 세 개로 표시합니다. */
    overflow: hidden; /* 텍스트가 컨테이너를 넘어가는 부분을 숨깁니다. */
    text-align: left;
    align-items: flex-end;
}

.col_filename2 .right {
    max-width: 100%; /* 컨테이너의 최대 너비를 100%로 설정합니다. */
    display: -webkit-box; /* 레이아웃에 WebKit의 flexbox를 사용합니다. */
    -webkit-box-orient: vertical; /* 텍스트를 수직으로 정렬합니다. */
    -webkit-line-clamp: 1; /* 최대 2줄까지 허용하고 3줄이 넘어가면 점 세 개로 표시합니다. */
    overflow: hidden; /* 텍스트가 컨테이너를 넘어가는 부분을 숨깁니다. */
    text-align: right;
    line-height: 40px;
}


/* CSS 파일 */
.ant-table-wrapper .ant-table-thead>tr>th {
    /* 행(tr)의 상단 및 하단 패딩 조정 */
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;

}

.ant-table-wrapper .ant-table-tbody>tr>td {
    /* 셀(td)의 좌우 패딩 조정 */
    padding: 10px 5px 0px;
}


.link-style-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #4b4b4b;
    cursor: pointer;
}

.link-style-button:focus {
    outline: none;
    color: #25A5EC;
}

.fileName_list {
    font-weight: bold; /* 글자를 두꺼운 스타일로 설정합니다. */
    color: #4b4b4b; /* 글자 색상을 검은색으로 설정합니다. */
    cursor: pointer; /* 마우스 커서가 요소 위에 있을 때 포인터 스타일로 변경합니다. */
}

.link-style-button:hover {
    color: #25A5EC; /* 마우스가 요소 위에 있을 때 글자 색상을 빨간색으로 변경합니다. */
}