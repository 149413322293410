

.custom-content {
    margin: 10px;
    min-height: 200px;
    background-color : white;
    height: calc(100vh - 70px);
    /*overflow-x: hidden;
    overflow-y: auto;


     */
}

.ant-select-single {
    width: 50%;
}